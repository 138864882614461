<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <b-card no-body class="">
    <b-card-title class="font-weight-bolder m-2">Proxy Exclude Lists</b-card-title>
    <div class="mb-1 d-flex align-items-baseline">
      <div class="ui-spacer"></div>
      <b-button @click="$refs.addListModal.show()" class="d-flex ml-auto btn-success mr-2">
        Create New List
      </b-button>
    </div>

    <section class="">
      <b-table
        class="data-table"
        hover
        :items="proxyExcludeLists"
        :fields="headers"
        :responsive="true"
      >

        <template #cell(domains)="data">
          {{ data.item.domains === '' ? 0 : data.item.domains.split('\n').length }}
        </template>

        <template #cell(global_lists)="data">
          {{ data.item.global_exclude_list_ids.length }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>

            <b-dropdown-item-button class="full-nested-btn" @click="$refs.updateListModal.show(data.item)">
              Update List
            </b-dropdown-item-button>

            <b-dropdown-item-button class="full-nested-btn" @click="deleteList(data.item)">
              Delete List
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row v-if="proxyExcludeLists.length === 0">
        <b-col class="ml-2 mt-1">
          <p>No results</p>
        </b-col>
      </b-row>
    </section>

    <add-list-modal ref="addListModal" :uid="uid" @refresh="getLists" />
    <update-list-modal ref="updateListModal" :uid="uid" @refresh="getLists" />


  </b-card>
</template>
<!--eslint-enable-->

<script>
import UpdateListModal from '@/views/enterprises/viewEnterprises/sections/UpdateListModal.vue'
import addListModal from '@/views/enterprises/viewEnterprises/sections/AddListModal.vue'
import EnterpriseService from '@/services/EnterpriseService'
import confirmBox from '@/views/parts/parts';
import Vue from 'vue';

export default {
  components: {
    UpdateListModal,
    addListModal,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedList: null,
      proxyExcludeLists: [],
      headers: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'domains',
          label: 'Domains',
        },
        {
          key: 'global_lists',
          label: 'Global Lists',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
    }
  },
  mounted() {
    this.getLists()
  },
  methods: {
    async getLists() {
      try {
        const listRequest = await EnterpriseService.getProxyExcludeLists(this.uid);
        this.proxyExcludeLists = listRequest.data;
      } catch (e) {
        Vue.$toast.error('Could not get proxy excluded lists, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async deleteList(list) {
      try {
        const result = await this.$bvModal.msgBoxConfirm(`Are you sure you wish to delete the list "${list.name}"`, confirmBox);

        if (result === true) {
          await EnterpriseService.deleteList(this.uid, list.id);
          this.$toast.success(`Deleted list ${list.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })
          await this.getLists()
        }
      } catch (err) {
        const res = err.response
        let errorText = 'Could not delete list, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
  },
}
</script>
