<style lang="scss"></style>

<template>
  <b-modal :id="modalName" title="Add new list" hide-footer>
    <div class="add-list-modal">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(addList)">
          <section class="mb-2">
            <div>
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="Name"
                :rules="{ required: true, min: 1 }"
              >
                <b-form-input
                  v-model="list.name"
                  :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name*"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </section>

          <section class="d-inline-flex full-width mt-2">
            <div class="ui-spacer" />
            <b-button class="mr-2" @click="close()">Cancel</b-button>
            <b-button type="submit" variant="primary">Add List</b-button>
          </section>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import EnterpriseService from '../../../../services/EnterpriseService';

export default {
  components: {},
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      modalName: 'modal-add-list',
      list: {
        name: '',
      },
    };
  },
  methods: {
    show() {
      this.list.name = ''
      this.$bvModal.show(this.modalName)
    },
    close() {
      this.$bvModal.hide(this.modalName)
    },
    addList() {
      EnterpriseService.createProxyExcludeList(this.uid, this.list).then(() => {
        this.$toast.success(`Added list ${this.list.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.$emit('refresh', true);
        this.close();
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not add list, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
